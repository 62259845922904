export const filterTableData = (list: any, arr: string) => {
    let obj: any = []
    list.filter((item: any) => {
        obj.push({
            text: item[arr],
            value: item[arr]
        })
    })
    return deWeight(obj)
}
const deWeight = (arr: any) => {
    for (let i = 0; i < arr.length - 1; i++) {
        for (let j = i + 1; j < arr.length; j++) {
            if (arr[i].text == arr[j].text) {
                arr.splice(j, 1);
                j--;
            }
        }
    }
    return arr;
}
